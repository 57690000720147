import { Injectable, OnDestroy } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  catchError,
  concatMap,
  delay,
  filter,
  map,
  mergeWith,
  Observable,
  OperatorFunction,
  retry,
  share,
  Subject,
  timer,
  UnaryFunction,
} from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TaggedPlayer } from 'src/app/modules/shared/services/web-nfc.service';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { RemoteRfidScannerService } from 'src/app/services/remote-rfid-scanner/remote-rfid-scanner.service';

@Injectable({
  providedIn: 'root',
})
export class NfcService implements OnDestroy {
  connected = false;

  public tagInfoWithPlayer = webSocket({
    url: 'ws://localhost:8080/Echo',
    deserializer: event => event.data?.toString(),
    openObserver: {
      next: () => {
        this.connected = true;
        setTimeout(
          () =>
            this.toast.info(
              this.t.instant('nfc.reader_connected'),
              this.t.instant('nfc.reader_connected_message')
            ),
          500
        );
      },
    },
    closeObserver: {
      next: () => {
        if (this.connected)
          this.toast.warn(
            this.t.instant('nfc.reader_disconnected'),
            this.t.instant('nfc.reader_disconnected_message'),
            { life: 5000 }
          );
        this.connected = false;
      },
    },
  })
    .pipe(
      retry({
        resetOnSuccess: true,
        delay: (_, count) =>
          timer(count < 20 && environment.production ? 500 : 60000),
      }),
      catchError(error => {
        this.toast.error('Failed to load tag info', error);
        console.error(error);
        return [];
      }),
      filterNullish<string>()
    )
    .pipe(
      mergeWith(
        this.remoteRfidScannerService.rfidTags$.pipe(map(x => x.tagId))
      ),
      concatMap(tag =>
        this.apollo
          .query<TagAndPlayerInfoQueryResult>({
            query: tagAndPlayerInfoQuery,
            variables: {
              tag,
            },
          })
          .pipe(map(x => ({ tag, info: x?.data.rfidTags.nodes[0] })))
      ),
      share()
    );

  private locked = false;

  unsubscribe$ = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private toast: ToastService,
    private t: TranslateService,
    private remoteRfidScannerService: RemoteRfidScannerService
  ) {
    this.tagInfoWithPlayer
      .pipe(takeUntil(this.unsubscribe$), delay(50))
      .subscribe(x => {
        if (x.info)
          this.toast.info(
            x.info.serialNumber
              ? `${x.info.id} - ${x.info.serialNumber}`
              : `${x.info.id}`,
            x.info.player
              ? `${x.info.player.id} - ${x.info.player.callSign}\n\n${x.tag}`
              : undefined,
            { life: 15000 }
          );
        else this.toast.warn('No info found for tag', x.tag, { life: 15000 });
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setLock() {
    this.locked = true;
  }

  hasLock() {
    return this.locked;
  }

  removeLock() {
    this.locked = false;
  }
}

const tagAndPlayerInfoQuery = gql`
  query AdminTagAndPlayerInfo($tag: String!) {
    rfidTags(filter: { tagId: { equal: $tag } }, skip: 0, take: 1) {
      nodes {
        id
        tagId
        serialNumber
        player {
          id
          callSign
        }
      }
    }
  }
`;

type TagAndPlayerInfoQueryResult = {
  rfidTags: {
    nodes: [TagInfo?];
  };
};

type TagInfo = {
  id: number;
  serialNumber?: string;
  player?: TaggedPlayer;
};

export function filterNullish<T>(): UnaryFunction<
  Observable<T | null | undefined>,
  Observable<T>
> {
  return filter(x => x != null) as OperatorFunction<T | null | undefined, T>;
}
