import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  take,
} from 'rxjs';
import { filterNullish } from 'src/app/services/nfc/nfc.service';
import { Apollo, gql } from 'apollo-angular';
import { ToastService } from 'src/app/services/toast/toast.service';
import { RemoteRfidScannerService } from 'src/app/services/remote-rfid-scanner/remote-rfid-scanner.service';

@Injectable({
  providedIn: 'root',
})
export class AutoChronoService {
  private chronoSubject$ = new Subject<ChronoMeasurement>();
  private chronoConnections: { [chronoId: number]: Subscription } = {};
  private chronoConnectionsSubject$ = new BehaviorSubject<number[]>([]);

  constructor(
    private apollo: Apollo,
    private toastService: ToastService,
    private remoteRfidScannerService: RemoteRfidScannerService
  ) {}

  get connectedTo$(): Observable<number[]> {
    return this.chronoConnectionsSubject$.asObservable();
  }

  get chronoResults$(): Observable<ChronoMeasurement> {
    return this.chronoSubject$.asObservable();
  }

  isConnected(chronoId: number) {
    return !!this.chronoConnections[chronoId];
  }

  toggleConnection(chronoId: number) {
    this.remoteRfidScannerService.toggleConnection(chronoId);

    if (this.isConnected(chronoId)) {
      this.chronoConnections[chronoId].unsubscribe();
      delete this.chronoConnections[chronoId];
      this.chronoConnectionsSubject$.next(
        this.chronoConnectionsSubject$.value.filter(x => x !== chronoId)
      );
      return;
    }

    this.chronoConnections[chronoId] = this.apollo
      .subscribe<{ speedMeasured: number }>({
        query: gql`
          subscription adminToolChrono($autoChronoId: Int!) {
            speedMeasured(autoChronoId: $autoChronoId)
          }
        `,
        variables: {
          autoChronoId: chronoId,
        },
        context: {},
      })
      .pipe(
        catchError(err => {
          console.error(err);
          return of();
        }),
        map(x => x.data?.speedMeasured),
        filterNullish()
      )
      .subscribe(x => {
        this.chronoSubject$.next({
          chronoId: chronoId,
          speed: x,
        });
        if (!this.chronoSubject$.observed)
          this.toastService.success(
            `Chrono ${chronoId}`,
            `Speed: ${x.toFixed(3)} m/s`
          );
      });

    this.chronoConnectionsSubject$.next([
      ...this.chronoConnectionsSubject$.value,
      chronoId,
    ]);
  }

  loadAvailableAutoChronos() {
    return this.apollo
      .query<{ autoChrono: { available: number[] } }>({
        query: gql`
          query adminToolChronoReload {
            autoChrono {
              available
            }
          }
        `,
      })
      .pipe(
        take(1),
        map(x => x.data.autoChrono.available),
        catchError(() => of([] as number[]))
      );
  }
}

type ChronoMeasurement = {
  speed: number;
  chronoId: number;
};
