import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { IdentityService } from 'src/app/services/identity/identity.service';
import { IdentityUser } from 'src/app/models/entities/identity-user';
import { catchError } from 'rxjs/operators';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { PersonalSettingsService } from 'src/app/modules/shared/services/personal-settings.service';
import { GuiService } from 'src/app/services/gui/gui.service';
import { DomPortal, Portal } from '@angular/cdk/portal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HeaderPortalService } from 'src/app/services/headerPortal.service';
import { Apollo } from 'apollo-angular';
import { AutoChronoService } from 'src/app/services/auto-chrono/auto-chrono.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  langList: MenuItem[] = [
    {
      label: 'English',
      command: () => {
        this.translate('en');
        this.setLang('en');
      },
    },
    {
      label: 'Deutsch',
      command: () => {
        this.translate('de');
        this.setLang('de');
      },
    },
  ];

  chronoList: MenuItem[] = [];

  userMenuList!: MenuItem[];
  user: IdentityUser | null = null;
  now: Date = new Date();
  showTime = false;
  isMobile = window.innerWidth <= 600;

  headerPortal?: Portal<unknown>;

  constructor(
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private sidebarService: SidebarService,
    private authService: OidcSecurityService,
    private identityService: IdentityService,
    private spinner: SpinnerService,
    private settings: PersonalSettingsService,
    private guiService: GuiService,
    private headerPortalService: HeaderPortalService,
    private apollo: Apollo,
    private autoChronoService: AutoChronoService
  ) {
    setInterval(() => {
      this.now = new Date();
    }, 1);

    this.guiService.isMobile$.pipe(takeUntilDestroyed()).subscribe(isMobile => {
      this.isMobile = isMobile;
    });

    this.identityService.identityUser$
      .pipe(
        takeUntilDestroyed(),
        catchError(e => {
          throw e;
        })
      )
      .subscribe(x => {
        this.user = x;
        if (x) {
          this.initUserMenuList();
        }
        this.spinner.hide();
      });

    this.headerPortalService.portal$
      .pipe(takeUntilDestroyed())
      .subscribe(portal => {
        if (!this.guiService.isMobile$.value)
          this.headerPortal = portal ? new DomPortal(portal) : portal;
      });
  }

  async checkMenuOpen() {
    let isMenuOpen = await this.settings.getSetting('isMenuOpen');
    if (isMenuOpen === null || isMenuOpen === undefined) {
      isMenuOpen = true;
    }
    if (isMenuOpen && window.innerWidth <= 1400) {
      isMenuOpen = false;
    }

    this.sidebarService.setIsMenuOpen(isMenuOpen);
  }

  async ngOnInit() {
    this.initUserMenuList();
    await this.loadLang();

    await this.checkMenuOpen();
  }

  @HostListener('window:resize', ['$event'])
  async onResize() {
    this.showTime = window.innerHeight == screen.height;
    await this.checkMenuOpen();
  }

  initUserMenuList(): void {
    if (!this.authService.isAuthenticated()) {
      return;
    }
    this.sidebarService.setMenu();

    this.userMenuList = [
      {
        label: `${this.user?.Firstname} ${this.user?.Lastname}`,
        disabled: true,
      },
      {
        separator: true,
      },
      {
        label: this.translateService.instant(
          this.showTime ? 'header.time.off' : 'header.time.on'
        ),
        command: () => {
          this.showTime = !this.showTime;
          this.initUserMenuList();
        },
        icon: 'pi pi-clock',
      },
      {
        label: this.translateService.instant('header.change_password'),
        command: () => {
          window.open(
            environment.auth.authority + '/account/editlogin',
            '_blank'
          );
        },
        icon: 'pi pi-key',
      },
      {
        label: this.translateService.instant('header.settings'),
        command: () => {
          window.open(environment.auth.authority + '/account/edit', '_blank');
        },
        icon: 'pi pi-cog',
      },
      {
        label: this.translateService.instant('menu.language'),
        command: () => {},
        items: this.langList,
        icon: 'pi pi-globe',
      },
      {
        label: this.translateService.instant('common.chrono'),
        routerLink: '/chrono',
        items: this.chronoList,
        icon: 'pi pi-gauge',
      },
      {
        label: this.translateService.instant('header.logout'),
        command: () => {
          this.authService.logoff().subscribe(() => {
            console.log('logout');
          });
        },
        icon: 'pi pi-sign-out',
      },
    ];
  }

  userMenuOpened() {
    this.autoChronoService.loadAvailableAutoChronos().subscribe(available => {
      this.chronoList =
        available.length === 0
          ? [
              {
                label: this.translateService.instant(
                  'common.no_chronos_available'
                ),
                disabled: true,
              },
            ]
          : available.map(x => ({
              label: `${this.translateService.instant('common.chrono')} ${x}`,
              icon: this.autoChronoService.isConnected(x)
                ? 'pi pi-link'
                : undefined,
              command: () => {
                this.autoChronoService.toggleConnection(x);
              },
            }));
      this.initUserMenuList();
    });
  }

  toggleMenu(): void {
    this.sidebarService.setIsMenuOpen(!this.sidebarService.isSidebarOpen);
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .subscribe(res => this.config.setTranslation(res));
  }

  async loadLang() {
    let lang = await this.settings.getSetting(`lang`);
    if (!lang) {
      lang = 'en';
      this.setLang(lang);
    }
    this.translate(lang);
  }

  setLang(lang: string) {
    this.settings.setSetting(`lang`, lang);
  }
}
