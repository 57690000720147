import { Injectable } from '@angular/core';
import { WeaponType } from 'src/app/models/entities/weapon-type.enum';
import {
  GameDayPlayerWeapon,
  PropulsionType,
  WeaponColor,
} from 'src/app/models/entities/chroni';

@Injectable({
  providedIn: 'root',
})
export class JouleRulesService {
  private rules: {
    [key in WeaponType]: {
      propulsionType: PropulsionType[];
      energyRanges: [WeaponColor, { min: number; max: number }][];
    }[];
  } = {
    [WeaponType.BACKUP]: [
      {
        propulsionType: [PropulsionType.AEP],
        energyRanges: [[WeaponColor.white, { min: 0, max: 0.59 }]],
      },
      {
        propulsionType: [PropulsionType.GAS, PropulsionType.SPRING],
        energyRanges: [[WeaponColor.white, { min: 0, max: 1.0 }]],
      },
      {
        propulsionType: [PropulsionType.HPA],
        energyRanges: [[WeaponColor.white, { min: 0, max: 0.9 }]],
      },
    ],
    [WeaponType.RIFLE]: [
      {
        propulsionType: [PropulsionType.AEG],
        energyRanges: [[WeaponColor.green, { min: 0, max: 0.59 }]],
      },
      {
        propulsionType: [
          PropulsionType.GAS,
          PropulsionType.SPRING,
          PropulsionType.SAEG,
        ],
        energyRanges: [
          [WeaponColor.green, { min: 0, max: 0.99 }],
          [WeaponColor.yellow, { min: 0.99, max: 1.39 }],
          [WeaponColor.red, { min: 1.39, max: 1.79 }],
        ],
      },
      {
        propulsionType: [PropulsionType.HPA],
        energyRanges: [
          [WeaponColor.green, { min: 0, max: 0.89 }],
          [WeaponColor.yellow, { min: 0.89, max: 1.29 }],
          [WeaponColor.red, { min: 1.29, max: 1.69 }],
        ],
      },
    ],
    [WeaponType.BOLT_ACTION_SNIPER]: [
      {
        propulsionType: [PropulsionType.GAS, PropulsionType.SPRING],
        energyRanges: [[WeaponColor.red, { min: 0, max: 2.49 }]],
      },
      {
        propulsionType: [PropulsionType.HPA],
        energyRanges: [[WeaponColor.red, { min: 0, max: 2.39 }]],
      },
    ],
  };

  private allowedTypesForU18 = new Map([
    [WeaponType.BACKUP, [PropulsionType.AEP, PropulsionType.SPRING]],
    [WeaponType.RIFLE, [PropulsionType.AEG, PropulsionType.SPRING]],
    [WeaponType.BOLT_ACTION_SNIPER, [PropulsionType.SPRING]],
  ]);

  getWeaponColor(
    isAdult: boolean,
    weapon: Pick<
      GameDayPlayerWeapon,
      'weaponType' | 'energy' | 'propulsionType'
    >
  ): WeaponColor | null {
    if (!isAdult) {
      const restrictedEnergy = 0.59;

      const allowedPropulsions = this.allowedTypesForU18.get(weapon.weaponType);

      if (
        !allowedPropulsions ||
        !allowedPropulsions.includes(weapon.propulsionType) ||
        weapon.energy > restrictedEnergy
      ) {
        return null;
      }
    }

    const weaponRules = this.rules[weapon.weaponType];
    if (!weaponRules) return null;

    for (const rule of weaponRules) {
      if (rule.propulsionType.includes(weapon.propulsionType)) {
        for (const [color, range] of rule.energyRanges) {
          if (weapon.energy >= range.min && weapon.energy <= range.max) {
            return color;
          }
        }
      }
    }

    return null;
  }
}
