import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Kind, OperationTypeNode } from 'graphql';
import { NgModule } from '@angular/core';
import { InMemoryCache, split } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { Logger } from 'src/app/services/logger/logger.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

const uri = environment.api.url + '/graphql';

const logger = new Logger('ApolloModule');

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink, httpClient: HttpClient) => {
        return {
          link: split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === Kind.OPERATION_DEFINITION &&
                definition.operation === OperationTypeNode.SUBSCRIPTION
              );
            },
            new GraphQLWsLink(
              createClient({
                url: uri,
                lazy: true,
                shouldRetry: () => true,
                retryAttempts: 10,
                on: {
                  error: error => logger.error(error),
                },
                connectionParams: async () => {
                  const token = await firstValueFrom(
                    httpClient.get<{ token: string }>(`${uri}/websocket/token`)
                  );

                  return {
                    authorization: token.token,
                  };
                },
              })
            ),
            httpLink.create({ uri })
          ),
          cache: new InMemoryCache(),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
            },
            query: {
              fetchPolicy: 'no-cache',
            },
            mutate: {
              fetchPolicy: 'no-cache',
            },
            subscription: {
              fetchPolicy: 'no-cache',
            },
          },
        };
      },
      deps: [HttpLink, HttpClient],
    },
  ],
})
export class GraphQLModule {}
