import {
  AttendanceState,
  GameDayCommentSubType,
  Squad,
} from 'src/app/models/entities/game-day';
import { Player } from 'src/app/models/entities/player';
import { RentalItem, RentalPackage } from 'src/app/models/entities/rentals';
import { WeaponType } from 'src/app/models/entities/weapon-type.enum';

export interface GameDayChrono extends GameDayCommentSubType {
  id: number;
  gameDayId: number;
  gameSquad: Squad;
  attendance: AttendanceState;
  parentalPermissionSigned: boolean;
  rentalPackages: RentalPackage[];
  rentalItems: RentalItem[];
  rentals: (RentalPackage | RentalItem)[];
  player: Player;
  weapons: GameDayPlayerWeapon[];
  hasBackupInRental: boolean;
  hasPrimaryInRental: boolean;
}

export enum PropulsionType {
  HPA = 'HPA',
  GAS = 'GAS',
  SPRING = 'SPRING',
  AEG = 'AEG',
  SAEG = 'SAEG',
  AEP = 'AEP',
}

export interface GameDayPlayerWeapon {
  id: number;
  name?: string;
  energy: number;
  weaponType: WeaponType;
  propulsionType: PropulsionType;
  color?: string;
}

export enum WeaponColor {
  white = '#fff',
  green = '#00ff00',
  yellow = '#ffff00',
  red = '#ff0000',
}
