import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  TableColumn,
  TableColumnSize,
  TableColumnType,
  TableRequiredPermissions,
} from 'src/app/modules/shared/components/table/table.model';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, Observable, of, Subject } from 'rxjs';
import { Filter } from 'src/app/models/graphql/filter/filter.model';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Sort, SortOrder } from 'src/app/models/graphql/filter/sort.model';
import { FormControl, FormGroup } from '@angular/forms';
import { QueryResult } from 'src/app/models/entities/query-result';
import { AddActionDirective } from 'src/app/modules/shared/components/table/add-action.directive';
import { EditActionDirective } from 'src/app/modules/shared/components/table/edit-action.directive';
import { DeleteActionDirective } from 'src/app/modules/shared/components/table/delete-action.directive';
import { MenuItem } from 'primeng/api';
import { CustomActionsDirective } from 'src/app/modules/shared/components/table/custom-actions.directive';
import { CustomColumnDirective } from 'src/app/modules/shared/components/table-column/custom-column.directive';
import { IdentityService } from 'src/app/services/identity/identity.service';
import { ContextMenu } from 'primeng/contextmenu';
import { TableService } from 'src/app/modules/shared/components/table/table.service';
import { Logger } from 'src/app/services/logger/logger.service';
import { GuiService } from 'src/app/services/gui/gui.service';
import { ComponentChanges } from 'src/app/modules/shared/components/chip-select/chip-select.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
export class TableComponent<T> implements OnChanges, OnDestroy {
  @ViewChild('dt') dt!: Table;
  @ViewChild('cm') contextMenuElement!: ContextMenu;

  @Input({ required: true }) name!: string;

  @Input() dataKey: string = 'id';
  @Input({ required: true }) result: QueryResult<T> = {
    nodes: [],
    count: 0,
    totalCount: 0,
  };
  @Input({ required: true }) countHeaderTranslation!: string;
  @Input({ required: true }) columns!: TableColumn[];
  @Input({ required: true }) requiredPermissions!: TableRequiredPermissions;

  @Input() useQueryParams = false;
  @Input() hidePaginator?: boolean;
  @Input() contextMenu?: MenuItem[];
  @Input() inlineEdit: boolean = true;
  @Input() responsiveLayout: 'stack' | 'scroll' = 'stack';

  protected _contextMenuItem!: T;
  set contextMenuItem(value: T) {
    this._contextMenuItem = value;
    this.logger.debug('Open context menu');
    this.openContextMenu.emit();
  }

  get contextMenuItem() {
    return this._contextMenuItem;
  }

  @Input() defaultFilter: Filter[] = [];
  @Input() defaultSort: Sort[] = [];
  @Input() filterInterceptors?: ((
    attribute: string,
    operator: string,
    value: string
  ) => Filter | undefined)[];
  @Input() sortInterceptors?: ((
    field: string,
    value: string
  ) => Sort | undefined)[];
  @Input() newNodeTemplate?: T = {} as T;
  @Input() rowDisabled?: (item: T) => boolean;

  @Input() onRowClick?: (item: T) => void;
  @Input() rowRouterLink?: (item: T) => string;

  @Output() reloadData: EventEmitter<void> = new EventEmitter<void>();
  @Output() add: EventEmitter<T> = new EventEmitter<T>();
  @Output() edit: EventEmitter<T> = new EventEmitter<T>();
  @Output() delete: EventEmitter<T> = new EventEmitter<T>();
  @Output() openContextMenu: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetFilterEvent: EventEmitter<void> = new EventEmitter<void>();

  filter: Filter[] = [];
  sort: Sort[] = [];
  skip: number = 0;
  take: number = this.table.rowsPerPageOptions[0];

  editable = false;
  sortable = false;
  filterable = false;

  protected loading: boolean = false;
  protected sortField: string = '';
  protected sortOrder: number = -1;
  protected queryFilter: { [key: string]: any }[] = [];
  protected filterForm!: FormGroup;
  protected defaultFilterForm!: FormGroup;

  public isEditingNew: boolean = false;
  public isEditing: boolean = false;

  private clonedNodes: T[] = [];
  public editingNode?: T;

  protected unsubscriber$ = new Subject<void>();

  protected hasPermissions = {
    add: true,
    edit: true,
    delete: true,
  };

  protected isMobile = false;
  protected showMobileFilters = false;
  protected dropdownOptions: { [key: string]: Observable<any> } = {};

  @ContentChild(CustomActionsDirective, { read: TemplateRef })
  customActionTemplate!: TemplateRef<any>;
  @ContentChild(AddActionDirective, { read: TemplateRef })
  addActionTemplate!: TemplateRef<any>;
  @ContentChild(EditActionDirective, { read: TemplateRef })
  editActionTemplate!: TemplateRef<any>;
  @ContentChild(DeleteActionDirective, { read: TemplateRef })
  deleteActionTemplate!: TemplateRef<any>;
  @ContentChild(CustomColumnDirective, { read: TemplateRef })
  customColumnTemplate!: TemplateRef<{
    $implicit: T;
    column: TableColumn;
  }>;

  protected logger = new Logger(`Table`);

  constructor(
    private identity: IdentityService,
    public table: TableService,
    private gui: GuiService
  ) {
    this.table.filter$.pipe(takeUntil(this.unsubscriber$)).subscribe(filter => {
      this.filter = filter;
    });
    this.table.sort$.pipe(takeUntil(this.unsubscriber$)).subscribe(sort => {
      this.sort = sort;
    });
    this.table.skip$.pipe(takeUntil(this.unsubscriber$)).subscribe(skip => {
      this.skip = skip;
    });
    this.table.take$.pipe(takeUntil(this.unsubscriber$)).subscribe(take => {
      this.take = take;
    });
    this.gui.isMobile$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(isMobile => {
        this.isMobile = isMobile;
      });

    this.table.onLoad.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
      // redirect service load to components
      this.reloadData.emit();
    });
  }

  async ngOnChanges({ columns }: ComponentChanges<TableComponent<T>>) {
    if (columns?.currentValue?.length === columns?.previousValue?.length)
      return;

    this.logger = new Logger(`Table_${this.name}`);
    this.table.reset({ withQueryParams: this.useQueryParams });

    this.editable = this.columns.some(x => x.editable);
    this.sortable = this.columns.some(x => x.sortable);
    this.filterable = this.columns.some(x => x.filterable);
    this.columns.forEach(x => {
      if (x.visible === undefined) {
        x.visible = true;
      }
    });

    this.buildDefaultFilterForm();
    this.setDefaultFilterForm();
    this.table.filter$.next(this.defaultFilter);
    this.setDefaultSort();
    this.setFilterForm();

    const user = await this.identity.getLoggedInUser();
    if (this.requiredPermissions.add) {
      this.hasPermissions.add = this.identity.hasUserPermission(
        user,
        this.requiredPermissions.add
      );
    }
    if (this.requiredPermissions.edit)
      this.hasPermissions.edit = this.identity.hasUserPermission(
        user,
        this.requiredPermissions.edit
      );
    if (this.requiredPermissions.delete)
      this.hasPermissions.delete = this.identity.hasUserPermission(
        user,
        this.requiredPermissions.delete
      );
  }

  ngOnDestroy() {
    this.logger.debug('Destroy');
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  protected setDefaultSort() {
    this.table.sort$.next(this.defaultSort);
    if (this.defaultSort.length > 0) {
      this.sortField = Object.keys(this.defaultSort[0])[0];
      this.sortOrder =
        this.defaultSort[0][this.sortField] === SortOrder.ASC ? 1 : -1;
    }
  }

  public buildDefaultFilterForm() {
    this.defaultFilterForm = new FormGroup({});
    this.columns
      .filter(x => x.filterable)
      .forEach(x => {
        let control!: FormControl;

        if (x.fuzzyFilterColumns) {
          control = new FormControl<string | null>(null);
          this.defaultFilterForm.addControl('fuzzy', control);
          return;
        } else if (
          x.type === TableColumnType.DROPDOWN &&
          x.dropdownOptions &&
          x.dropdownOptions.multiple
        ) {
          control = new FormControl<[] | null>(null);
        } else if (x.type === TableColumnType.STRING) {
          control = new FormControl<string | null>(null);
        } else if (x.type === TableColumnType.NUMBER) {
          control = new FormControl<number | null>(null);
        } else if (x.type === TableColumnType.BOOLEAN) {
          control = new FormControl<boolean | null>(null);
        } else if (x.type === TableColumnType.DATE) {
          control = new FormControl<Date | null>(null);
        } else {
          control = new FormControl<any | null>(null);
        }
        this.defaultFilterForm.addControl(x.name, control);
      });
  }

  public setDefaultFilterForm() {
    this.defaultFilter.forEach(x => {
      Object.keys(x).forEach(key => {
        const value = x[key];
        if (!(key in this.defaultFilterForm.controls)) {
          return;
        }
        if (typeof value === 'object' && value !== null) {
          Object.keys(value).forEach(subKey => {
            this.defaultFilterForm.get([key])?.setValue(value[subKey]);
          });
        } else {
          this.defaultFilterForm.get(key)?.setValue(value);
        }
      });
    });
  }

  public setFilterForm() {
    this.filterForm = this.defaultFilterForm;

    this.filterForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$), debounceTime(300))
      .subscribe(changes => {
        this.logger.trace('Filter input', changes);
        if (this.filterForm.disabled) {
          return;
        }

        this.queryFilter = [];
        this.table.filter$.next([]);
        for (const atr in changes) {
          const column = this.columns.find(x => x.name === atr);
          let value = changes[atr];

          if (value !== '' && value !== null && value !== undefined) {
            let operator = 'contains';

            if (Array.isArray(value)) {
              operator = 'in';
            } else if (
              column?.type == TableColumnType.BOOLEAN &&
              typeof value === 'boolean'
            ) {
              operator = 'equal';
            } else if (
              column &&
              [
                TableColumnType.NUMBER,
                TableColumnType.ID,
                TableColumnType.PLAYER_ID,
              ].includes(column.type) &&
              (typeof value === 'number' || this.isNumeric(value))
            ) {
              operator = 'equal';
              value = +value;
            }

            this.queryFilter.push({ [atr]: value });

            const interceptedFilters: Filter[] = [];
            if (this.filterInterceptors) {
              this.filterInterceptors.forEach(x => {
                const interceptedFilter = x(atr, operator, value);
                if (!interceptedFilter) return;
                interceptedFilters.push(interceptedFilter);
              });
            }

            if (interceptedFilters.length > 0) {
              interceptedFilters.forEach(x => {
                this.filter.push(x);
                this.table.filter$.next(this.filter);
              });
            } else {
              const filter: Filter = {};
              filter[atr] = {};
              filter[atr][operator] = value;

              this.filter.push(filter);
              this.logger.debug('Trigger filter');
              this.table.filter$.next(this.filter);
            }
          }
        }

        this.logger.debug('Trigger reload data');
        this.table.onLoad.emit();
      });

    this.queryFilter.forEach(filter => {
      Object.keys(filter).forEach(key => {
        // preload hardcoded filter
        if (key === 'team') {
          const c = this.columns.find(x => x.name === 'team');
          if (c) this.openDropdown(c);
        }
        if (key === 'attendance') {
          const c = this.columns.find(x => x.name === 'team');
          if (c) this.openDropdown(c);
        }
        this.filterForm.controls[key].setValue(filter[key]);
      });
    });
  }

  private isNumeric(val: string): boolean {
    return !isNaN(Number(val));
  }

  public resetFilters(): void {
    this.logger.debug('Reset filter');
    this.filterForm.reset();
    this.setDefaultFilterForm();
    this.resetFilterEvent.emit();
  }

  public resetFilter(filter: string): void {
    this.filterForm.controls[filter].reset();
  }

  public resetSort(table: Table): void {
    this.logger.debug('Reset sort');
    this.table.sort$.next([]);
    table.reset();
  }

  public nextPage(event: TableLazyLoadEvent): void {
    this.logger.trace('next page', event);
    if (event.rows !== undefined && event.rows !== null)
      this.table.take$.next(event.rows);
    if (event.first !== undefined && event.first !== null)
      this.table.skip$.next(event.first);

    if (event.sortField) {
      const field = event.sortField.toString().split('.')[0];
      const value =
        event.sortOrder === 1
          ? SortOrder.ASC
          : event.sortOrder === -1
            ? SortOrder.DESC
            : SortOrder.ASC;

      this.table.sort$.next([]);
      const interceptedSorts: Sort[] = [];
      if (this.sortInterceptors) {
        this.sortInterceptors.forEach(x => {
          const interceptedSort = x(field, value);
          if (!interceptedSort) return;
          interceptedSorts.push(interceptedSort);
        });
      }

      if (interceptedSorts.length > 0) {
        interceptedSorts.forEach(x => {
          this.sort.push(x);
          this.table.sort$.next(this.sort);
        });
      } else {
        const sort: Sort = {};
        sort[field] = value;

        this.sort.push(sort);
        this.table.sort$.next(this.sort);
      }
    }

    this.logger.debug('Trigger reload data');
    this.table.onLoad.emit();
  }

  public addNewNode() {
    const newNode = JSON.parse(JSON.stringify(this.newNodeTemplate ?? {}));
    this.result.nodes = [newNode, ...this.result.nodes];

    this.dt.initRowEdit(newNode);
    this.onRowEditInit(newNode, this.result.nodes.length);
    this.isEditingNew = true;
    this.filterForm.disable();
  }

  public onRowEditInit(object: T, index: number): void {
    this.isEditing = true;
    this.clonedNodes[index] = { ...object };
    this.editingNode = object;
  }

  public onRowEditCancel(index: number): void {
    this.isEditing = false;
    this.filterForm.enable();
    if (this.isEditingNew) {
      this.result.nodes.splice(index, 1);
      delete this.clonedNodes[index];
      this.isEditingNew = false;
      this.editingNode = undefined;
      return;
    }

    this.result.nodes[index] = this.clonedNodes[index];
    delete this.clonedNodes[index];
    this.editingNode = undefined;
  }

  public onRowEditSave(node: T, index: number) {
    this.filterForm.enable();
    if (
      this.isEditingNew &&
      JSON.stringify(node) === (this.newNodeTemplate ?? ({} as T))
    ) {
      this.isEditingNew = false;
      this.result.nodes.splice(index, 1);
      this.editingNode = undefined;
      return;
    }
    this.isEditing = false;

    if (this.isEditingNew) {
      this.logger.debug('Trigger add');
      this.add.emit(node);
      this.isEditingNew = false;
      this.editingNode = undefined;
      return;
    }
    this.logger.debug('Trigger edit');
    this.edit.emit(node);
    this.editingNode = undefined;
  }

  public toggleMobileMenu() {
    if (!this.isMobile) {
      this.showMobileFilters = false;
      return;
    }

    this.showMobileFilters = !this.showMobileFilters;
  }

  public filterColumnInShownFilters(column: TableColumn): boolean {
    const shownFilters = [
      TableColumnType.STRING,
      TableColumnType.COMMENT,
      TableColumnType.ID,
      TableColumnType.PLAYER_ID,
      TableColumnType.NUMBER,
      TableColumnType.BOOLEAN,
      TableColumnType.DATE,
      TableColumnType.DROPDOWN,
      TableColumnType.TEAM,
      TableColumnType.ATTENDANCE,
    ];
    return shownFilters.includes(column.type) || !this.isMobile;
  }

  protected getFuzzyColspan(columns: string[]): number {
    return this.columns.filter(x => columns.includes(x.name) && x.visible)
      .length;
  }

  protected openDropdown(column: TableColumn) {
    if (column.type !== TableColumnType.DROPDOWN) return;

    const fallbackOptions = column.dropdownOptions?.options ?? [];

    if (!column.dropdownOptions?.optionGetter) {
      this.dropdownOptions[column.name] = of(fallbackOptions);
      return;
    }
    this.dropdownOptions[column.name] = column.dropdownOptions.optionGetter();
  }

  protected readonly TableColumnType = TableColumnType;
  protected readonly TableColumnSize = TableColumnSize;
}
