import { Component, EventEmitter, Input, Output } from '@angular/core';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface TileSelectItem {
  label: string;
  value: any;
  active?: boolean;
  icon?: string;
  image?: string;
}

@Component({
  selector: 'app-tile-select',
  templateUrl: './tile-select.component.html',
  styleUrl: './tile-select.component.scss',
})
export class TileSelectComponent {
  @Input() addDialogHeaderTKey: string = 'common.unknown';
  @Input() addDropdownPlaceholderTKey: string = 'common.unknown';
  @Input() optionLabel: string | undefined = 'name';
  @Input() styleClass: string = '';
  @Input() activeStyleClass: string = '';

  @Input() items!: TileSelectItem[];

  _selectedItemsValues: any[] = [];
  @Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();

  @Input()
  set selectedItems(items: any[]) {
    this._selectedItemsValues = items;
    this.selectedItemsChange.emit(this._selectedItemsValues);
  }

  get selectedItems(): any[] {
    return this._selectedItemsValues;
  }

  @Input() disabled: boolean = false;

  constructor() {}

  selectItem(item: TileSelectItem) {
    if (this._selectedItemsValues.includes(item.value)) {
      item.active = false;
      this._selectedItemsValues.splice(
        this._selectedItemsValues.indexOf(item.value),
        1
      );
    } else {
      item.active = true;
      this._selectedItemsValues.push(item.value);
    }
    this.selectedItemsChange.emit(this._selectedItemsValues);
  }
}
