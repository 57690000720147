import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Logger } from 'src/app/services/logger/logger.service';
import { Duration, format, intervalToDuration } from 'date-fns';

const logger = new Logger('GuiService');

@Injectable({
  providedIn: 'root',
})
export class GuiService {
  isMobile$ = new BehaviorSubject<boolean>(this.isMobileByWindowWith());
  isTablet$ = new BehaviorSubject<boolean>(this.isTabletByWindowWith());
  isFullscreen$ = new BehaviorSubject<boolean>(
    window.innerHeight === screen.height
  );

  constructor() {}

  private isMobileByWindowWith(): boolean {
    return window.innerWidth <= 600;
  }

  private isTabletByWindowWith(): boolean {
    return window.innerWidth <= 960;
  }

  public checkWindowSize() {
    logger.debug('check window size', {
      mobile: this.isMobileByWindowWith(),
      tablet: this.isTabletByWindowWith(),
      size: window.innerWidth,
    });

    this.isFullscreen$.next(window.innerHeight === screen.height);

    const isMobile = this.isMobileByWindowWith();
    if (isMobile) {
      this.isMobile$.next(true);
      this.isTablet$.next(false);
      return;
    }
    this.isTablet$.next(this.isTabletByWindowWith());
    this.isMobile$.next(false);
  }

  public hexToRGB(hex: string, alpha: number): string {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  public formatTime(date: Date): string {
    return format(date, 'HH:mm');
  }

  public formatDateTime(date: Date): string {
    return format(date, 'dd.MM.yyyy HH:mm');
  }
}

/*
duration is either a number in seconds or a Duration object
*/
export function formatTimeSpan(duration: number | Duration): string {
  const timeSpan =
    typeof duration === 'number'
      ? intervalToDuration({ start: 0, end: duration * 1000 })
      : duration;

  if (
    !!timeSpan.years ||
    !!timeSpan.months ||
    !!timeSpan.weeks ||
    (timeSpan.days ?? 0) > 1
  ) {
    const years = timeSpan.years ? `${timeSpan.years} years ` : '';
    const months = timeSpan.months ? `${timeSpan.months} months ` : '';
    const weeks = timeSpan.weeks ? `${timeSpan.weeks} weeks ` : '';
    const days = timeSpan.days ? `${timeSpan.days} days ` : '';

    return `${years}${months}${weeks}${days}${pad(timeSpan.hours)}:${pad(timeSpan.minutes)}:${pad(timeSpan.seconds)}`;
  }
  if (timeSpan.days) {
    return `${timeSpan.days}:${pad(timeSpan.hours)}:${pad(timeSpan.minutes)}:${pad(timeSpan.seconds)}`;
  }
  if (timeSpan.hours) {
    return `${pad(timeSpan.hours)}:${pad(timeSpan.minutes)}:${pad(timeSpan.seconds)}`;
  }

  return `${pad(timeSpan.minutes)}:${pad(timeSpan.seconds)}`;
}

function pad(num?: number): string {
  return num ? num.toString().padStart(2, '0') : '00';
}
