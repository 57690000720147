<header [ngClass]="{ 'header-with-sub-header': isMobile && showTime }">
  <div class="header-wrapper">
    <div class="header">
      <div class="logo-button-wrapper">
        <p-button
          type="button"
          icon="pi pi-bars"
          class="btn icon-btn p-button-text"
          (onClick)="toggleMenu()"></p-button>
      </div>
      <div class="logo">
        <img src="/assets/images/Area49_Logo.svg" alt="logo" />
      </div>
      <!--    <div class="app-name">-->
      <!--      <h1>{{ 'header.header' | translate }}</h1>-->
      <!--    </div>-->
    </div>
    <div *ngIf="showTime && !isMobile" class="time">
      {{ now | date: 'dd.MM.yy HH:mm:ss' }}
    </div>
    <div class="header-portal">
      @if (!isMobile) {
        <ng-template [cdkPortalOutlet]="headerPortal"></ng-template>
      }
    </div>
    <div class="header-menu logo-button-wrapper">
      <app-nfc-player></app-nfc-player>
      <div class="logo-button-wrapper">
        <p-button
          type="button"
          icon="pi pi-user"
          class="btn icon-btn p-button-text"
          (onClick)="userMenu.toggle($event)"></p-button>
        <p-tieredMenu
          #userMenu
          [popup]="true"
          [model]="userMenuList"
          (onShow)="userMenuOpened()"
          class="user-menu"></p-tieredMenu>
      </div>
    </div>
  </div>
  <div
    *ngIf="showTime && isMobile"
    class="sub-header time flex justify-center items-center">
    {{ now | date: 'dd.MM.yy HH:mm:ss' }}
  </div>
</header>
