import { Injectable } from '@angular/core';
import {
  catchError,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  take,
} from 'rxjs';
import { filterNullish } from 'src/app/services/nfc/nfc.service';
import { Apollo, gql } from 'apollo-angular';
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class RemoteRfidScannerService {
  private rfidSubject$ = new Subject<RfidTag>();
  private rfidConnections: { [scannerId: number]: Subscription } = {};

  constructor(
    private apollo: Apollo,
    private toastService: ToastService
  ) {}

  get rfidTags$(): Observable<RfidTag> {
    return this.rfidSubject$.asObservable();
  }

  isConnected(scannerId: number) {
    return !!this.rfidConnections[scannerId];
  }

  toggleConnection(scannerId: number) {
    if (this.isConnected(scannerId)) {
      this.rfidConnections[scannerId].unsubscribe();
      delete this.rfidConnections[scannerId];
      return;
    }

    this.rfidConnections[scannerId] = this.apollo
      .subscribe<{ tagScanned: { tagId: string } }>({
        query: gql`
          subscription adminToolRfidScanner($scannerId: Int!) {
            tagScanned(scannerId: $scannerId) {
              tagId
            }
          }
        `,
        variables: {
          scannerId,
        },
        context: {},
      })
      .pipe(
        catchError(err => {
          console.error(err);
          return of();
        }),
        map(x => x.data?.tagScanned.tagId),
        filterNullish()
      )
      .subscribe(x => {
        this.rfidSubject$.next({
          scannerId,
          tagId: x,
        });
        if (!this.rfidSubject$.observed)
          this.toastService.success(`RfidScanner ${scannerId}`, `TagId: ${x}`);
      });
  }

  loadAvailableScanners() {
    return this.apollo
      .query<{ rfidScanner: { available: number[] } }>({
        query: gql`
          query adminToolRfidScannerReload {
            rfidScanner {
              available
            }
          }
        `,
      })
      .pipe(
        take(1),
        map(x => x.data.rfidScanner.available),
        catchError(() => of([] as number[]))
      );
  }
}

type RfidTag = {
  tagId: string;
  scannerId: number;
};
