import { inject, Injectable, ProviderToken } from '@angular/core';
import { CommandsBase } from 'src/app/commands/commands-base';
import { GeneralCommands } from 'src/app/commands/general.commands';
import { SpinnerCommands } from 'src/app/commands/spinner.commands';
import { NfcCommands } from 'src/app/commands/nfc.commands';
import { Command } from 'src/app/modules/shared/components/terminal/terminal.component';
import { LoggingCommands } from 'src/app/commands/logging.commands';

@Injectable({
  providedIn: 'root',
})
export class CommandCollector extends CommandsBase {
  private commands: Command[] = [];

  constructor() {
    super();

    this.extend(GeneralCommands);
    this.extend(SpinnerCommands);
    this.extend(NfcCommands);
    this.extend(LoggingCommands);
  }

  get() {
    return this.commands;
  }

  private extend(c: ProviderToken<CommandsBase>) {
    this.commands = this.commands.concat(inject(c).get());
  }
}
