import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { TabMenuModule } from 'primeng/tabmenu';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PaginatorModule } from 'primeng/paginator';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { SidebarModule } from 'primeng/sidebar';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { HideableColumnComponent } from 'src/app/modules/shared/components/hideable-column/hideable-column.component';
import { HideableHeaderComponent } from 'src/app/modules/shared/components/hideable-header/hideable-header.component';
import { ChipModule } from 'primeng/chip';
import { ChipSelectComponent } from 'src/app/modules/shared/components/chip-select/chip-select.component';
import { StepsModule } from 'primeng/steps';
import { BadgeModule } from 'primeng/badge';
import { TileSelectComponent } from 'src/app/modules/shared/components/tile-select/tile-select.component';
import { PlayerProfileDialogComponent } from 'src/app/modules/shared/components/player-profile-dialog/player-profile-dialog.component';
import { PlayerIdColumnComponent } from 'src/app/modules/shared/components/player-id-column/player-id-column.component';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TableComponent } from 'src/app/modules/shared/components/table/table.component';
import { TableColumnComponent } from 'src/app/modules/shared/components/table-column/table-column.component';
import { MultiSelectHiddenColumnsComponent } from 'src/app/modules/shared/components/multi-select-hidden-columns/multi-select-hidden-columns.component';
import { DragDropModule } from 'primeng/dragdrop';
import { InputSwitchModule } from 'primeng/inputswitch';
import {
  EditSidebarComponent,
  EditSidebarContentDirective,
  EditSidebarHeaderDirective,
} from 'src/app/modules/shared/components/edit-sidebar/edit-sidebar.component';
import { AddActionDirective } from 'src/app/modules/shared/components/table/add-action.directive';
import { EditActionDirective } from 'src/app/modules/shared/components/table/edit-action.directive';
import { DeleteActionDirective } from 'src/app/modules/shared/components/table/delete-action.directive';
import { CustomActionsDirective } from 'src/app/modules/shared/components/table/custom-actions.directive';
import { CustomColumnDirective } from 'src/app/modules/shared/components/table-column/custom-column.directive';
import { AuthGuard } from 'src/app/modules/shared/guards/auth.guard';
import { PermissionGuard } from 'src/app/modules/shared/guards/permission.guard';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FirstLetterPipe } from 'src/app/modules/shared/pipes/first-letter/first-letter.pipe';
import { ShortcutComponent } from 'src/app/modules/shared/shortcut/shortcut.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectableDirective } from 'src/app/modules/shared/select-items/selectable.directive';
import { GamedayInfoComponent } from 'src/app/modules/shared/components/gameday-info/gameday-info.component';
import { TerminalComponent } from 'src/app/modules/shared/components/terminal/terminal.component';
import { TerminalModule, TerminalService } from 'primeng/terminal';
import { RatingModule } from 'primeng/rating';
import { SingleChipSelectComponent } from 'src/app/modules/shared/components/single-chip-select/single-chip-select.component';
import { NfcPlayerComponent } from 'src/app/modules/shared/components/nfc-player/nfc-player.component';
import { CombinationLockWheelComponent } from 'src/app/modules/shared/components/combination-lock-wheel/combination-lock-wheel.component';
import { JouleInputComponent } from 'src/app/modules/shared/components/joule-input/joule-input.component';
import { FocusTrapModule } from 'primeng/focustrap';
import { InputMaskModule } from 'primeng/inputmask';
import { ProgressBarComponent } from 'src/app/modules/shared/components/progress-bar/progress-bar.component';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {
  ColumnContentComponent,
  ColumnContentInputDirective,
  ColumnContentOutputDirective,
} from 'src/app/modules/shared/components/table-column/column-handler/column-content.component';
import { MapPipe } from 'src/app/modules/shared/pipes/map.pipe';
import { JoinPipe } from 'src/app/modules/shared/pipes/join.pipe';
import { IconComponent } from 'src/app/components/icon/icon/icon.component';
import { TieredMenuModule } from 'primeng/tieredmenu';

const PrimeNgModules = [
  StepsModule,
  DialogModule,
  ToastModule,
  ProgressSpinnerModule,
  ButtonModule,
  ButtonGroupModule,
  FormsModule,
  ReactiveFormsModule,
  ConfirmDialogModule,
  TableModule,
  InputTextModule,
  CheckboxModule,
  DropdownModule,
  TranslateModule,
  ImageModule,
  TabMenuModule,
  MenubarModule,
  PanelMenuModule,
  TieredMenuModule,
  CardModule,
  MultiSelectModule,
  SplitButtonModule,
  CalendarModule,
  PaginatorModule,
  DataViewModule,
  TagModule,
  SidebarModule,
  FileUploadModule,
  MenuModule,
  ChipModule,
  BadgeModule,
  ContextMenuModule,
  DragDropModule,
  InputSwitchModule,
  SelectButtonModule,
  BreadcrumbModule,
  OverlayPanelModule,
  SplitButtonModule,
  TerminalModule,
  RatingModule,
  FocusTrapModule,
  InputMaskModule,
  TriStateCheckboxModule,
  InputTextareaModule,
];

const sharedComponents = [
  HideableColumnComponent,
  HideableHeaderComponent,
  MultiSelectHiddenColumnsComponent,
  ChipSelectComponent,
  TileSelectComponent,
  PlayerProfileDialogComponent,
  PlayerIdColumnComponent,
  TableColumnComponent,
  GamedayInfoComponent,
  ShortcutComponent,
  TerminalComponent,
  SingleChipSelectComponent,
  NfcPlayerComponent,
  CombinationLockWheelComponent,
  JouleInputComponent,
  CustomColumnDirective,
  TableComponent,
  CustomActionsDirective,
  AddActionDirective,
  EditActionDirective,
  DeleteActionDirective,
  EditSidebarComponent,
  EditSidebarHeaderDirective,
  EditSidebarContentDirective,
  SelectableDirective,
  FirstLetterPipe,
  MapPipe,
  JoinPipe,
  ProgressBarComponent,
  ColumnContentComponent,
  ColumnContentInputDirective,
  ColumnContentOutputDirective,
  IconComponent,
];

@NgModule({
  declarations: [...sharedComponents],
  imports: [CommonModule, ...PrimeNgModules],
  exports: [...PrimeNgModules, ...sharedComponents],
  providers: [AuthGuard, PermissionGuard, TerminalService],
})
export class SharedModule {}
