import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TileSelectItem } from 'src/app/modules/shared/components/tile-select/tile-select.component';

export interface ChipSelectItem<TValue, TItem> {
  item: TItem & { __typename?: string };
  label: string;
  value: TValue;
  icon?: string;
  multiple?: boolean;
}

export interface SelectedChipSelectItem<TValue, TItem>
  extends ChipSelectItem<TValue, TItem> {
  count: number;
}

@Component({
  selector: 'app-chip-select',
  templateUrl: './chip-select.component.html',
  styleUrl: './chip-select.component.scss',
})
export class ChipSelectComponent<TValue, TItem>
  implements OnChanges, OnDestroy
{
  @Input() name: string = 'chip-select';
  @Input() addDialogHeaderTKey: string = 'common.unknown';
  @Input() addDropdownPlaceholderTKey: string = 'common.unknown';

  @Input() disabled: boolean = false;
  @Output() addButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  isAddDialogVisible = false;

  @Input({ required: true }) items: ChipSelectItem<TValue, TItem>[] | undefined;
  @Input({ required: true }) itemValueGetter!: (item: TItem) => TValue;

  @Input({ required: true })
  selectedItems?: (TItem & { __typename?: string })[] = [];

  @Output() selectedItemsChange: EventEmitter<TItem[]> = new EventEmitter<
    TItem[]
  >();

  _viewItems: SelectedChipSelectItem<TValue, TItem>[] = [];

  @Input() quickSelectionItems!: TileSelectItem[] | undefined;
  quickSelectItems: TValue[] = [];

  unsubscribe$ = new Subject<void>();

  constructor() {
    this.selectedItemsChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.recalculateViewItems();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges({
    items,
    selectedItems,
  }: ComponentChanges<ChipSelectComponent<TValue, TItem>>) {
    if (
      (items &&
        JSON.stringify(items.currentValue) !==
          JSON.stringify(items.previousValue)) ||
      (selectedItems &&
        JSON.stringify(selectedItems.currentValue) !==
          JSON.stringify(selectedItems.previousValue))
    ) {
      this.recalculateViewItems();
    }

    if (!this.selectedItems) this.selectedItems = [];
  }

  recalculateViewItems() {
    this._viewItems =
      this.items
        ?.map(item => {
          return {
            item: item.item,
            label: item.label,
            value: item.value,
            icon: item.icon,
            multiple: item.multiple,
            count:
              this.selectedItems?.filter(
                selectedItem =>
                  this.itemValueGetter(selectedItem) === item.value &&
                  selectedItem.__typename === item.item.__typename
              ).length ?? 0,
          };
        })
        .filter(x => x.count) ?? [];
  }

  removeItem(item: ChipSelectItem<TValue, TItem>): void {
    const index =
      this.selectedItems?.findIndex(
        x => this.itemValueGetter(x) === item.value
      ) ?? -1;

    if (index === -1) {
      throw new Error('Item not found in selected items.');
    }

    this.selectedItems!.splice(index, 1);
    this.selectedItemsChange.emit(this.selectedItems);
  }

  openDialog() {
    this.addButtonClicked.emit();
    this.resetTileSelect();
    this.isAddDialogVisible = true;
  }

  dropDownModelChange(items: ChipSelectItem<TValue, TItem>[]) {
    this.selectedItems = items.map(x => x.item);
    this.selectedItemsChange.emit(this.selectedItems);
  }

  dialogIncreaseItemCount(
    event: MouseEvent,
    item: ChipSelectItem<TValue, TItem>
  ) {
    event.stopPropagation();
    this.selectedItemsChange.emit([...(this.selectedItems ?? []), item.item]);
  }

  dialogDecreaseItemCount(
    event: MouseEvent,
    item: ChipSelectItem<TValue, TItem>
  ) {
    event.stopPropagation();
    this.removeItem(item);
  }

  saveAddItem() {
    this.quickSelectItems.forEach(x => {
      const item = this.items?.find(
        i =>
          // @ts-expect-error TS2339
          (i.item.id &&
            // @ts-expect-error TS2339
            i.item.id === x.id &&
            // @ts-expect-error TS2339
            i.item.__typename === x.__typename) ||
          JSON.stringify(i.item) === JSON.stringify(x)
      );

      if (item) this.selectedItems?.push(item.item);
    });

    this.selectedItemsChange.emit(this.selectedItems);
    this.isAddDialogVisible = false;
  }

  resetTileSelect() {
    if (!this.items) {
      return;
    }
    if (!this.quickSelectionItems) {
      return;
    }
    this.quickSelectionItems = this.quickSelectionItems.map(x => {
      return {
        label: x.label,
        value: x.value,
        icon: x.icon,
      };
    });
  }

  typeItems(
    selectedItems: SelectedChipSelectItem<TValue, TItem>[]
  ): SelectedChipSelectItem<TValue, TItem>[] {
    return selectedItems;
  }
}

export type ComponentChange<T, P extends keyof T> = {
  previousValue: T[P];
  currentValue: T[P];
  firstChange: boolean;
};

export type ComponentChanges<T> = {
  [P in keyof T]?: ComponentChange<T, P>;
};
