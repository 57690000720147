import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  styleUrls: ['./icon.component.scss'],
  template: `
    <svg
      class="icon"
      fill="currentColor"
      viewBox="0 0 24 24"
      [style]="{ display: 'inline-block', width: size, height: size }">
      <path [attr.d]="path" />
    </svg>
  `,
})
export class IconComponent {
  @Input({ required: true }) path!: string;
  @Input() size = '1.5rem';
}
