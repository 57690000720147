import { QueryResult } from 'src/app/models/entities/query-result';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Injectable } from '@angular/core';
import {
  ComponentDataService,
  DeleteNode,
} from 'src/app/modules/shared/base/component.data.service';
import { Player } from 'src/app/models/entities/player';
import {
  DELETE_PLAYERS,
  GET_PLAYERS,
} from 'src/app/models/graphql/players.query';
import { Filter } from 'src/app/models/graphql/filter/filter.model';
import { Sort } from 'src/app/models/graphql/filter/sort.model';
import { WithHistory } from 'src/app/modules/shared/components/slideIn/history/history.entry';
import { EDITOR_FRAGMENT } from 'src/app/models/graphql/editor.query';
import {
  GraphQlResponse,
  MutlipartService,
} from 'src/app/services/multipart/multipart.service';
import { ToastService } from 'src/app/services/toast/toast.service';

type StatsLoaderConfig = {
  withHistory?: boolean;
  withPersonalInfo?: boolean;
  withStats?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class PlayersDataService
  extends ComponentDataService
  implements DeleteNode<Player>
{
  statsPlayerId$ = new Subject<number | undefined>();

  constructor(
    private spinner: SpinnerService,
    private apollo: Apollo,
    private multipart: MutlipartService,
    private toastService: ToastService
  ) {
    super();
  }

  loadAggregatedStatsById(id: number, config?: StatsLoaderConfig) {
    return this.apollo
      .query<{ players: QueryResult<Player> }>({
        query: gql`
          query aggregatedStatsByPlayerId(
            $id: Int!
            $excludeEmpty: Boolean!
            $withHistory: Boolean!
            $withPersonalInfo: Boolean!
            $withStats: Boolean!
          ) {
            players(filter: [{ id: { equal: $id } }]) {
              nodes {
                id
                ...playerInfo
                aggregatedStats {
                  ...stats @include(if: $withStats)
                  history(excludeEmpty: $excludeEmpty)
                    @include(if: $withHistory) {
                    updatedUtc
                    editor {
                      ...EDITOR
                    }
                    changedProperties
                    currentValue {
                      ...stats
                    }
                    previousValue {
                      ...stats
                    }
                  }
                }
              }
            }
          }

          fragment playerInfo on Player {
            identityId @include(if: $withPersonalInfo)
            callSign
            mail @include(if: $withPersonalInfo)
            firstName @include(if: $withPersonalInfo)
            lastName @include(if: $withPersonalInfo)
            address @include(if: $withPersonalInfo)
            city @include(if: $withPersonalInfo)
            banned @include(if: $withPersonalInfo)
            comment @include(if: $withPersonalInfo)
            birthdate @include(if: $withPersonalInfo)
            isAdult
            nonLiabilityWaverSigned
            neverPlayed
            orga
            team {
              id
              name
              abbreviation
            }
            colorHex
            hasViolations
            fileId
          }

          fragment stats on AggregatedPlayerStats {
            gameCount
            gamesWon
            gamesLost
            gamesWinLossRatio
            kd
            score
            credits
            captures
            neutralisations
            kills
            killAssists
            deaths
            repairs
            demolitions
            resupplies
            kdRatio
            flags
            bombs
            loot
            vip
            playTimeSeconds
            legacyLevel
            level
            nextLevelScore
            nextLevelPercentage
          }
          ${EDITOR_FRAGMENT}
        `,
        variables: {
          id: id,
          excludeEmpty: true,
          withHistory: !!config?.withHistory,
          withPersonalInfo: !!config?.withPersonalInfo,
          withStats: !!config?.withStats,
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        }),
        map(x => x.data.players.nodes[0])
      );
  }

  load(filter: Filter, sort: Sort, skip: number, take: number) {
    return this.apollo
      .query<{ players: QueryResult<Player> }>({
        query: GET_PLAYERS,
        variables: {
          filter: filter,
          sort: sort,
          skip: skip,
          take: take,
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        })
      );
  }

  loadById(
    playerId: number | null,
    showEmptyEntries: boolean
  ): Observable<WithHistory<Player> | null> {
    if (!playerId) return of(null);

    return this.apollo
      .query<{ players: { nodes: WithHistory<Player>[] } }>({
        query: gql`
          query adminToolRentalObject($id: Int!, $excludeEmpty: Boolean!) {
            players(take: 1, filter: { id: { equal: $id } }) {
              nodes {
                id
                ...player
                history(excludeEmpty: $excludeEmpty) {
                  updatedUtc
                  editor {
                    ...EDITOR
                  }
                  changedProperties
                  currentValue {
                    ...player
                  }
                  previousValue {
                    ...player
                  }
                }
              }
            }
          }

          fragment player on Player {
            id
            identityId
            callSign
            firstName
            lastName
            mail
            address
            city
            banned
            comment
            birthdate
            isAdult
            nonLiabilityWaverSigned
            neverPlayed
            colorHex
            fileId
            team {
              id
              name
              abbreviation
            }
          }
          ${EDITOR_FRAGMENT}
        `,
        variables: { id: playerId, excludeEmpty: !showEmptyEntries },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        }),
        map(x => x.data.players.nodes[0] ?? null)
      );
  }

  upsert(player: PlayerEditable, file: File | null, id: number | null) {
    const files: { [key: string]: File } = {};
    if (file) files['file'] = file!;

    const input = {
      ...player,
      fileReferenceName: file ? `file` : undefined,
    };

    const query = `
        mutation adminToolPlayers(${id ? updateInput : createInput}) {
            player {
                ${id ? updateFragment(id) : createFragment}
            }
        }
    `;

    return this.multipart
      .query<UpsertResult>({
        query: query,
        variables: { input },
        operationName: 'adminToolPlayers',
        files: files,
      })
      .pipe(
        catchError((x?: { error: GraphQlResponse<UpsertResult> }) => {
          if (!x?.error.errors) throw x;

          x.error.errors.forEach((err: { extensions: { code: string } }) => {
            switch (err.extensions.code) {
              case 'DUPLICATE_CALL_SIGN':
                this.toastService.error('errors.player.duplicate_call_sign');
                break;
              case 'DUPLICATE_IDENTITY_ID':
                this.toastService.error('errors.player.duplicate_identity_id');
                break;
            }
          });

          return of(null);
        }),
        map(x => x?.data.player.create?.id ?? x?.data.player.update?.id)
      );
  }

  deleteNode(object: Player) {
    return this.apollo
      .mutate({
        mutation: DELETE_PLAYERS,
        variables: {
          id: object.id,
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        })
      );
  }

  assignTag(nfcTagId: string, playerId: number, tagId: number | undefined) {
    if (tagId)
      return this.apollo
        .mutate<{ rfidTags: { edit: { id: number } } }>({
          mutation: gql`
            mutation ActivateTagOnPlayer($tag: RfidTagEdit!) {
              rfidTags {
                edit(tag: $tag) {
                  id
                }
              }
            }
          `,
          variables: {
            tag: {
              id: tagId,
              playerId: playerId,
              active: true,
            },
          },
        })
        .pipe(
          catchError(err => {
            this.spinner.hide();
            throw err;
          }),
          filter(x => !!x.data),
          map(x => x.data!.rfidTags.edit.id)
        );

    return this.apollo
      .mutate<{ rfidTags: { create: { id: number } } }>({
        mutation: gql`
          mutation CreateTagOnPlayer($tag: RfidTagCreate!) {
            rfidTags {
              create(tag: $tag) {
                id
              }
            }
          }
        `,
        variables: {
          tag: {
            tagId: nfcTagId,
            playerId: playerId,
            active: true,
          },
        },
      })
      .pipe(map(x => x.data!.rfidTags.create.id));
  }

  newAvatarUpload(timeout?: NodeJS.Timeout) {
    const subject = new Subject<AvatarUpload>();

    const concurrency = 4;
    subject
      .pipe(
        mergeMap(file => this.uploadAvatar(file), concurrency),
        catchError(err => {
          throw err;
        }),
        finalize(() => {
          if (timeout) clearTimeout(timeout);
          this.toastService.success('player.avatar.uploaded');
        })
      )
      .subscribe();

    return subject;
  }

  private uploadAvatar(avatarUpload: AvatarUpload) {
    return this.multipart
      .query<{ player: { avatarUpload: boolean } }>({
        operationName: 'adminToolAvatarImport',
        query: `
        mutation adminToolAvatarImport($playerId: Int!, $fileReferenceName: String!) {
          player {
            update(id: $playerId, input: { fileReferenceName: $fileReferenceName }) {
              id
            }
          }
        }
      `,
        variables: {
          playerId: avatarUpload.playerId,
          fileReferenceName: 'file',
        },
        files: {
          file: avatarUpload.file,
        },
      })
      .pipe(
        map(result => result.data.player.avatarUpload),
        map<boolean, [number, boolean]>(result => [
          avatarUpload.playerId,
          result,
        ])
      );
  }
}

type UpsertResult = {
  player: { update?: { id: number }; create?: { id: number } };
};

type AvatarUpload = {
  file: File;
  playerId: number;
};

export type PlayerEditable = {
  callSign?: string;
  mail?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  address?: string | null;
  city?: string | null;
  banned?: boolean;
  comment?: string | null;
  birthdate?: string | null;
  nonLiabilityWaverSigned?: boolean;
  neverPlayed?: boolean;
  orga?: boolean;
  teamId?: number | null;
  identityId?: string | null;
  fileId?: string | null;
};

const createInput = '$input: PlayerCreateInput!';
const updateInput = '$input: PlayerEditInput!';
const createFragment = 'create(input: $input) { id }';

function updateFragment(playerId: number) {
  return `update(id: ${playerId} input: $input) { id }`;
}
