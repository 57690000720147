import { Component, Input } from '@angular/core';
import { Player } from 'src/app/models/entities/player';
import { PlayerProfileDialogService } from 'src/app/modules/shared/services/player-profile-dialog/player-profile-dialog.service';
import { filterNullish } from 'src/app/services/nfc/nfc.service';
import { PlayersDataService } from 'src/app/modules/admin/players/players.data.service';

@Component({
  selector: 'app-player-id-column',
  templateUrl: './player-id-column.component.html',
  styleUrl: './player-id-column.component.scss',
})
export class PlayerIdColumnComponent {
  @Input() player!: Player;
  @Input() withHistory = false;

  constructor(
    private playerProfileDialogService: PlayerProfileDialogService,
    private playerDataService: PlayersDataService
  ) {}

  openProfile(event: MouseEvent) {
    event.stopPropagation();

    this.playerDataService
      .loadAggregatedStatsById(this.player.id, { withStats: true })
      .pipe(filterNullish())
      .subscribe(value =>
        this.playerProfileDialogService.openPlayerProfile(value)
      );
  }
}
