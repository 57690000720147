import { gql } from 'apollo-angular';

export const GET_RENTAL_PACKAGES = gql`
  query (
    $filter: [RentalPackageFilter!]!
    $sort: [RentalPackageSort!]!
    $skip: Int
    $take: Int
  ) {
    rental {
      packages(filter: $filter, skip: $skip, sort: $sort, take: $take) {
        totalCount
        count
        nodes {
          id
          name
          amountAvailable
          public
          price
          items {
            id
            name
            forAdultsOnly
            amountAvailable
          }
        }
      }
    }
  }
`;
export const GET_ALL_RENTAL_PACKAGES = gql`
  query {
    rental {
      packages(take: 99999) {
        totalCount
        count
        nodes {
          id
          name
          amountAvailable
          items {
            ...item
          }
        }
      }
      items(take: 99999) {
        totalCount
        count
        nodes {
          ...item
        }
      }
    }
  }

  fragment item on RentalItem {
    id
    name
    forAdultsOnly
    amountAvailable
    weaponType
    favorite
  }
`;

export const GET_RENTAL_FAVORITE_ITEMS = gql`
  query {
    rental {
      items(filter: { favorite: { equal: true } }, take: 99999) {
        totalCount
        count
        nodes {
          id
          name
          forAdultsOnly
          amountAvailable
          weaponType
        }
      }
    }
  }
`;

export const ADD_RENTAL_PACKAGES = gql`
  mutation ($input: RentalPackageCreateInput!) {
    rental {
      package {
        create(input: $input) {
          id
          name
          amountAvailable
        }
      }
    }
  }
`;

export const EDIT_RENTAL_PACKAGES = gql`
  mutation ($id: Int!, $input: RentalPackageEditInput!) {
    rental {
      package {
        update(id: $id, input: $input) {
          id
          name
          amountAvailable
        }
      }
    }
  }
`;

export const DELETE_RENTAL_PACKAGES = gql`
  mutation ($id: Int!) {
    rental {
      package {
        delete(id: $id)
      }
    }
  }
`;
