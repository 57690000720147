import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {
  filter,
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  of,
} from 'rxjs';
import { differenceInSeconds, fromUnixTime } from 'date-fns';
import { Logger } from 'src/app/services/logger/logger.service';
import { environment } from 'src/environments/environment';

const log = new Logger('TokenInterceptorService');

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  intercept(
    req: HttpRequest<string>,
    next: HttpHandler
  ): Observable<HttpEvent<string>> {
    return this.oidcSecurityService.getAccessToken().pipe(
      mergeMap(token =>
        token &&
        this.isTokenExpired(token) &&
        req.url.includes(environment.api.url)
          ? from(
              navigator.locks.request('refresh', { mode: 'exclusive' }, () =>
                firstValueFrom(
                  this.oidcSecurityService.getAccessToken().pipe(
                    filter(this.isTokenExpired.bind(this)),
                    mergeMap(() =>
                      this.oidcSecurityService.forceRefreshSession()
                    ),
                    map(x => {
                      if (x.isAuthenticated) return null;
                      throw new Error('not authenticated');
                    })
                  ),
                  { defaultValue: null }
                )
              )
            ).pipe(mergeMap(() => this.oidcSecurityService.getAccessToken()))
          : of(token)
      ),
      mergeMap(token =>
        token &&
        (req.url.includes(environment.api.url) ||
          req.url.includes(environment.auth.authority))
          ? next.handle(
              req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
              })
            )
          : next.handle(req)
      )
    );
  }

  isTokenExpired(token: string): boolean {
    if (!token) return true;

    const content = this.parseJwt(token);
    if (!content) return true;

    const lifetimeInSeconds = differenceInSeconds(
      fromUnixTime(content.exp as number),
      new Date()
    );
    log.trace('access token lifetime in seconds: ', lifetimeInSeconds);
    return lifetimeInSeconds < 60;
  }

  // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
  parseJwt(token: string): { exp: number } | null {
    const parts = token.split('.');
    if (parts.length !== 3) return null;

    const base64Url = parts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
