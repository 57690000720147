import { Component } from '@angular/core';
import { Player, PlayerAggregatedStats } from 'src/app/models/entities/player';
import { PlayerProfileDialogService } from 'src/app/modules/shared/services/player-profile-dialog/player-profile-dialog.service';

@Component({
  selector: 'app-player-profile-dialog',
  templateUrl: './player-profile-dialog.component.html',
  styleUrl: './player-profile-dialog.component.scss',
})
export class PlayerProfileDialogComponent {
  player: Player | undefined;

  get visible() {
    return this.player !== undefined;
  }

  set visible(value: boolean) {
    if (!value) {
      this.player = undefined;
    }
  }

  aggregatedStatsAttributes: {
    TKey: string;
    property: keyof PlayerAggregatedStats;
  }[] = [
    { TKey: 'game_count', property: 'gameCount' },
    { TKey: 'games_won', property: 'gamesWon' },
    { TKey: 'games_lost', property: 'gamesLost' },
    { TKey: 'games_win_loss_ratio', property: 'gamesWinLossRatio' },
    { TKey: 'kd', property: 'kd' },
    { TKey: 'score', property: 'score' },
    { TKey: 'credits', property: 'credits' },
    { TKey: 'captures', property: 'captures' },
    { TKey: 'neutralisations', property: 'neutralisations' },
    { TKey: 'kills', property: 'kills' },
    { TKey: 'kill_assists', property: 'killAssists' },
    { TKey: 'deaths', property: 'deaths' },
    { TKey: 'repairs', property: 'repairs' },
    { TKey: 'demolitions', property: 'demolitions' },
    { TKey: 'resupplies', property: 'resupplies' },
    { TKey: 'kd_ratio', property: 'kdRatio' },
    { TKey: 'flags', property: 'flags' },
    { TKey: 'bombs', property: 'bombs' },
    { TKey: 'loot', property: 'loot' },
    { TKey: 'vip', property: 'vip' },
    { TKey: 'play_time_seconds', property: 'playTimeSeconds' },
    { TKey: 'legacy_level', property: 'legacyLevel' },
    { TKey: 'level', property: 'level' },
    { TKey: 'next_level_score', property: 'nextLevelScore' },
    { TKey: 'next_level_percentage', property: 'nextLevelPercentage' },
  ];

  constructor(private playerProfileDialogService: PlayerProfileDialogService) {
    this.playerProfileDialogService.player$.subscribe(player => {
      this.player = player;
    });
  }
}
